import React, { FC } from 'react';
import SEO from '../components/seo';
import Layout from '../containers/layout/layout';
import Header from '../containers/layout/header';
import Footer from '../containers/layout/footer';
import PeopleOfTomorrow from '../containers/personas/people-of-tomorrow';
import SubpageHeader from '../containers/personas/subpage-header';

const GroupsPage: FC<{ location: string }> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Humans of 2030+" />
      <Header fixedDisplayVarient={true} />
      <main className="site-wrapper-reveal">
        <SubpageHeader
          title={'Humans of 2030+'}
          breadcrumbs={[
            {
              path: '/',
              title: 'Overview',
            },
            {
              path: '/groups',
              title: 'The People',
            },
          ]}
        />
        <PeopleOfTomorrow />
      </main>
      <Footer />
    </Layout>
  );
};

export default GroupsPage;
